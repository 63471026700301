import React, { useState, useRef } from "react";
import {
  Modal,
  Text,
  Button,
  Link,
  Input,
  FormElement,
  Image
} from "@nextui-org/react";
import supabase from '../../api/supabase';
import { Login } from 'react-iconly'
import { Provider } from "@supabase/supabase-js";
const logo = new URL('../../assets/brand/logo.svg', import.meta.url).toString();
const iconGoogle = new URL('../../assets/auth/google.svg', import.meta.url);
const iconMicrosoft = new URL('../../assets/auth/microsoft.svg', import.meta.url);

export default function Authenticate() {
  const [open, setOpen] = useState(true);
  const emailRef = useRef({} as FormElement);
  const passwordRef = useRef({} as HTMLInputElement);
  const [errorText, setErrorText] = useState('');

  async function signInWithEmail() {
    const { error } = await supabase.auth.signInWithPassword({
      email: emailRef.current?.value,
      password: passwordRef.current?.value,
    });

    if (error) {
      setErrorText(error.message);
      console.error(error);
    } else {
      setOpen(false);
      window.location.reload();
    }
  }

  async function signInWithOAuth(provider: Provider) {
    const { error } = await supabase.auth.signInWithOAuth({
      provider: provider,
    })

    if (error) {
      setErrorText(error.message);
      console.error(error);
    }
    else {
      setOpen(false);
      window.location.reload();
    }
  }

  async function resetPassword() {
    const { error } = await supabase.auth.resetPasswordForEmail(emailRef.current?.value, {
      redirectTo: 'https://app.optimize.business/reset-password',
    })

    if (error) {
      setErrorText(error.message)
    }
  }

  return (
    <Modal
      open={open}
      blur
      preventClose
    >
      <Modal.Header css={{ my: "1em" }}>
        <Image src={logo} height={50} />
      </Modal.Header>
      <Modal.Body>
        <Text>Don't have an account? <Link href="/signup">Sign up</Link></Text>
        <Input
          clearable
          placeholder="Email"
          bordered
          ref={emailRef}
        ></Input>
        <Input.Password
          clearable
          placeholder="Password"
          bordered
          ref={passwordRef}
        />
        <Text css={{ textAlign: "right" }}><Link onPress={resetPassword}>Forgot password</Link></Text>
        <Text color="error">{errorText}</Text>
        <Button auto flat onPress={signInWithEmail} icon={<Login />}>Sign In</Button>
        <Text css={{ textAlign: "center", p: "0.5em", opacity: 0.5 }}>or</Text>
        <Button flat onPress={() => signInWithOAuth('google')} icon={<Image src={iconGoogle.toString()} alt="logo" />}>Sign in with Google</Button>
        <Button flat onPress={() => signInWithOAuth('azure')} icon={<Image src={iconMicrosoft.toString()} alt="logo" />}>Sign in with Microsoft</Button>
      </Modal.Body>
      <Modal.Footer>
      </Modal.Footer>
    </Modal>
  )
}
