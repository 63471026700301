import { Container, Spacer } from "@nextui-org/react";
import React from "react";
import SubNav, { Item } from "../navigation/subnav";
import { Outlet } from "react-router-dom";

export default function OrganizationSettingsLayout() {
  const items: Array<Item> = [
    { name: "Organization Settings", href: "/organization/settings" },
    { name: "User Management", href: "/organization/settings/users" },
    { name: "Billing", href: "/organization/settings/billing" },
  ]

  return (
    <>
      <SubNav items={items} />
      <Spacer y={1} />
      <Container>
        <Outlet />
      </Container>
    </>
  )
}
