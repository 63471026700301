import React from 'react';
import { Text } from "@nextui-org/react";

export default function OrganizationSettings() {
  return (
    <>
      <Text h1>Organization Settings</Text>
    </>
  );
}
