import React, { createContext, useCallback, useContext } from "react"
import { useAuth } from "./Auth"
import { useEffect } from "react"
import { useState } from "react"
import supabase, { UserMetadata } from "../api/supabase"


const UserMetadataContext = createContext<[
  UserMetadata | null | undefined,
  (metadata: UserMetadata) => Promise<void>
]>([
  null,
  async () => { },
])

export const UserMetadataProvider = ({ children }: any) => {
  const [metadata, setMetadata] = useState<UserMetadata | null>()

  const updateMetadata = useCallback(async (metadata: UserMetadata) => {
    if (metadata) {
      const { data: { user }, error } = await supabase.auth.updateUser({ data: metadata });
      if (error) throw error
    }
  }, [metadata])

  useEffect(() => {
    const setData = async () => {
      const { data: { session }, error } = await supabase.auth.getSession();
      if (error) throw error;
      setMetadata(session?.user.user_metadata as UserMetadata)
    }

    const { data: listener } = supabase.auth.onAuthStateChange((_event, session) => {
      setMetadata(session?.user.user_metadata as UserMetadata)
    })

    setData()

    return (
      // cleanup subscription
      listener?.subscription.unsubscribe()
    )
  }, [])

  return (
    <UserMetadataContext.Provider value={[metadata, updateMetadata]}>
      {children}
    </UserMetadataContext.Provider>
  )
}

/**
 * Creates a user metadata hook.
 * 
 * @returns User metadata context
 */
export const useUserMetadata = () => {
  return useContext(UserMetadataContext);
}
