import React, {
  useState,
  useEffect,
} from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Navbar,
  Dropdown,
  Avatar,
  Text,
  Row,
} from "@nextui-org/react";
import supabase from '../../api/supabase';
import { Database } from '../../api/database.types';
import { Logout } from 'react-iconly';
import { useAuth } from '../../hooks/Auth';
import { useUserMetadata } from '../../hooks/UserMetadata';

type Organization = Database['public']['Tables']['organization']['Row'];

const defaultIcon = new URL('../../assets/brand/icon.svg', import.meta.url).toString();

export default function AccountDropdown() {
  const { user, signOut } = useAuth()
  const [userMeta, setUserMeta] = useUserMetadata()
  const [orgs, setOrgs] = useState(Array<Organization>());
  const navigate = useNavigate();

  // when the user clicks an option from the menu
  const onAction = async (key: React.Key) => {
    switch (key) {
      case "logout": {
        const { error } = await supabase.auth.signOut()
        if (!error) window.location.href = "https://optimize.business"

        break;
      }
      case "org-settings": {
        navigate("/organization/settings");
        break;
      }
      case "new-org": {
        navigate("/organization/create");
        break;
      }
      default: {
        const newOrg = orgs.find((org) => org.id == Number(key))

        if (newOrg) {
          await supabase.auth.updateUser({ data: { current_org: newOrg } })

          await supabase.auth.refreshSession()
        }

        window.location.reload()
      }
    }
  }

  useEffect(() => {
    // fetch organizations the user has access to
    const loadOrgs = async () => {
      const { data, error } = await supabase
        .from('organization')
        .select()

      if (error) throw error
      setOrgs(data)
    }

    loadOrgs()
  }, []);

  return (
    <>
      <Navbar.Content>
        <Dropdown isBordered placement="bottom-right" disableAnimation>
          <Dropdown.Button
            light
            ripple={false}
            icon={<Avatar squared text="Optimize" src={defaultIcon} />}
          >
            <Text b>{userMeta?.current_org?.name}</Text>
          </Dropdown.Button>
          <Dropdown.Menu
            css={{ $$dropdownMenuWidth: "auto" }}
            onAction={onAction}
          >
            <Dropdown.Section
              title={`${user?.user_metadata?.name} - ${user?.email}`}
              items={orgs}
            >
              {(org) => (
                <Dropdown.Item
                  key={org.id}
                  icon={<Avatar squared size="sm" src={defaultIcon} />}
                  textValue={org.name}
                >
                  <Row justify="space-between" align="center">
                    {org.name}
                    {userMeta?.current_org?.id == org.id &&
                      <Text size="small" color="grey">Current</Text>}
                  </Row>
                </Dropdown.Item>
              )}
            </Dropdown.Section>
            <Dropdown.Section>
              <Dropdown.Item key="org-settings">Organization settings</Dropdown.Item>
              <Dropdown.Item key="new-org">Create or join an organization</Dropdown.Item>
              <Dropdown.Item key="logout" icon={<Logout />}>Log out</Dropdown.Item>
            </Dropdown.Section>
          </Dropdown.Menu>
        </Dropdown>
      </Navbar.Content>
    </>
  )
}
