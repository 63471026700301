import React from 'react';
import {
  Text,
} from "@nextui-org/react";

export default function InventoryOverview() {

  return (
    <>
      <Text h1>Inventory</Text>
    </>
  );
}
