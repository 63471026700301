import React, { useEffect, useState } from "react";
import {
  Modal,
  Text,
  Grid,
  Input,
  useInput,
  Button,
  PressEvent,
  Spacer,
  Row,
  useModal,
} from "@nextui-org/react";
import supabase from "../../api/supabase";
import { Delete } from "react-iconly";
import CancelButton from "../input/cancel";
import { useUserMetadata } from "../../hooks/UserMetadata";

interface Props {
  location_id?: number,
  new?: boolean,
  open?: boolean,
  onClose?: () => void,
}

export default function Location(props: Props) {
  const [userMeta, setUserMeta] = useUserMetadata();
  const address = useInput("");
  const name = useInput("");
  const [errorText, setErrorText] = useState('');
  const { setVisible, bindings } = useModal(true);

  useEffect(() => {
    if (!props.new) fetchLocations();
  }, []);

  const fetchLocations = async () => {
    const { data, error } = await supabase
      .from('location')
      .select()
      .eq('location_id', props.location_id)
      .single();

    if (error) console.log('error', error);
    else {
      name.setValue(data.location_name)
      address.setValue(data.location_address ?? "")
    }
  };

  const saveHandler = async () => {
    const { error } = await supabase
      .from('location')
      .update({
        location_name: name.value,
        location_address: address.value,
        hidden: false
      })
      .eq('location_id', props.location_id);

    if (error == null) {
      props.onClose && props.onClose();
    } else {
      setErrorText(error.message);
    }
  }

  const createHandler = async () => {
    if (!userMeta?.current_org) {
      setErrorText("No organization is currently selected.")
      return;
    }

    const { data, error } = await supabase
      .from('location')
      .insert({
        location_name: name.value,
        location_address: address.value,
        organization_id: userMeta?.current_org?.id,
      });

    if (error == null) {
      props.onClose && props.onClose();
      setVisible(false);
    } else {
      setErrorText(error.message);
    }
  }

  const cancelHandler = (event: PressEvent) => {
    props.onClose && props.onClose();
    setVisible(false);
  }

  const deleteHandler = async () => {
    const { error } = await supabase
    .from('location')
    .update({
      hidden: true
    })
    .eq('location_id', props.location_id);

    if (error == null) {
      props.onClose && props.onClose();
      setVisible(false);
    } else {
      setErrorText(error.message);
    }
  }

  return (
    <Modal
      {...bindings}
      preventClose={props.new}
      onClose={props.onClose}
      width="90%"
      css={{ mw: "800px", margin: "auto" }}
    >
      <Modal.Header>
        {!props.new && <Text h3>Edit Location</Text>}
        {props.new && <Text h3>Create Location</Text>}
      </Modal.Header>
      <Modal.Body>
        <Grid.Container gap={1}>
        <Grid xs={12}><Input {...name.bindings} width="$full" label="Name" /></Grid>
          <Grid xs={12}><Input {...address.bindings} width="$full" label="Address" /></Grid>
        </Grid.Container>
        <Spacer y={0} />
        <Text color="error">{errorText}</Text>
      </Modal.Body>
      <Modal.Footer>
        <Row gap={1} justify="space-between">
          <Row>
            {!props.new && <Button onPress={deleteHandler} auto bordered light css={{ color: "$gray500", borderColor: "$gray500" }}><Delete /></Button>}
          </Row>
          <Row justify="flex-end">
            <CancelButton onPress={cancelHandler} />
            <Spacer x={0.5} />
            {!props.new && <Button onPress={saveHandler}>Save</Button>}
            {props.new && <Button onPress={createHandler}>Create</Button>}
          </Row>
        </Row>
      </Modal.Footer>
    </Modal>
  );
}
