import React, { useContext, useEffect, useState } from "react";
import {
  Modal,
  Text,
  Textarea,
  Grid,
  Input,
  useInput,
  Button,
  PressEvent,
  Container,
  Spacer,
  Row,
  useModal,
  Table,
  Tooltip,
} from "@nextui-org/react";
import supabase, { UserMetadata } from "../../api/supabase";
import { Delete, Plus } from "react-iconly";
import CancelButton from "../input/cancel";
import { useUserMetadata } from "../../hooks/UserMetadata";

interface Props {
  part_number?: number,
  new?: boolean,
  open?: boolean,
  onClose?: () => void,
}

export default function Part(props: Props) {
  const [userMeta, setUserMeta] = useUserMetadata();
  const sku = useInput("");
  const name = useInput("");
  const description = useInput("");
  const [timeCreated, setTimeCreated] = useState(new Date());
  const [timeModified, setTimeModifier] = useState(new Date());
  const [errorText, setErrorText] = useState('');
  const { setVisible, bindings } = useModal(true);

  useEffect(() => {
    if (!props.new) fetchParts();
  }, []);

  const fetchParts = async () => {
    const { data, error } = await supabase
      .from('part')
      .select()
      .eq('part_number', props.part_number)
      .single();
    if (error) console.log('error', error);
    else {
      name.setValue(data.part_name)
      sku.setValue(data.part_sku ?? "")
      description.setValue(data.part_description ?? "");
    }
  };

  const saveHandler = async () => {
    const { error } = await supabase
      .from('part')
      .update({
        part_sku: sku.value,
        part_name: name.value,
        part_description: description.value,
        part_metadata: { type: "component" }
      })
      .eq('part_number', props.part_number);

    if (error == null) {
      props.onClose && props.onClose();
    } else {
      setErrorText(error.message);
    }
  }

  const createHandler = async () => {
    if (!userMeta?.current_org) {
      setErrorText("No organization is currently selected.")
      return;
    }

    const { data, error } = await supabase
      .from('part')
      .insert({
        part_name: name.value,
        part_sku: sku.value,
        part_description: description.value,
        part_metadata: { type: "component" },
        organization_id: userMeta?.current_org?.id,
      });

    if (error == null) {
      props.onClose && props.onClose();
      setVisible(false);
    } else {
      setErrorText(error.message);
    }
  }

  const cancelHandler = (event: PressEvent) => {
    props.onClose && props.onClose();
    setVisible(false);
  }

  const deleteHandler = async () => {
    const { error } = await supabase
      .from('part')
      .update({ archived: true })
      .eq('part_number', props.part_number);

    if (error == null) {
      props.onClose && props.onClose();
      setVisible(false);
    } else {
      setErrorText(error.message);
    }
  }

  return (
    <Modal
      {...bindings}
      preventClose={props.new}
      onClose={props.onClose}
      width="90%"
      css={{ mw: "800px", margin: "auto" }}
    >
      <Modal.Header>
        {!props.new && <Text h3>Edit part</Text>}
        {props.new && <Text h3>Create part</Text>}
      </Modal.Header>
      <Modal.Body>
        <Grid.Container gap={1}>
          <Grid xs={4}><Input {...sku.bindings} width="$full" label="SKU" css={{ fontFamily: "$mono" }} /></Grid>
          <Grid xs={8}><Input {...name.bindings} width="$full" label="Name" /></Grid>
          <Grid xs={12}>
            <Textarea {...description.bindings} minRows={1} width="$full" label="Description"></Textarea>
          </Grid>
          <Grid xs={12} alignItems="flex-start" direction="column">
            <label className="nextui-input-block-label" style={{ marginBottom: "6px" }}>Suppliers</label>
            <Table
              shadow={false}
              selectionMode="none"
              containerCss={{
                minWidth: "100%",
              }}
              css={{
                padding: "0"
              }}
            >
              <Table.Header>
                <Table.Column>Name</Table.Column>
                <Table.Column align="end"> </Table.Column>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>Test</Table.Cell>
                  <Table.Cell>
                    <Tooltip content="Remove supplier" color="error" css={{ zIndex: "$max" }} enterDelay={250}>
                      <Delete />
                    </Tooltip>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
            <Button icon={<Plus />} light>Add supplier</Button>
          </Grid>
          <Grid xs={3} direction="column">
            <Input width="$full" label="Weight (kg)" />
            <Input width="$full" label="Width" />
          </Grid>
          <Grid xs={3} direction="column">
            <Input width="$full" label="Height" />
            <Input width="$full" label="Length" />
          </Grid>
        </Grid.Container>
        <Spacer y={0} />
        <Container gap={0.5}>
          {!props.new && <>
            <Text size={12} color="grey">Created {timeCreated.toLocaleDateString()} {timeCreated.toLocaleTimeString()}</Text>
            <Text size={12} color="grey">Modified {timeModified.toLocaleDateString()} {timeModified.toLocaleTimeString()}</Text>
          </>}
        </Container>
        <Text color="error">{errorText}</Text>
      </Modal.Body>
      <Modal.Footer>
        <Row gap={1} justify="space-between">
          <Row>
            {!props.new && <Button onPress={deleteHandler} auto bordered light css={{ color: "$gray500", borderColor: "$gray500" }}><Delete /></Button>}
          </Row>
          <Row justify="flex-end">
            <CancelButton onPress={cancelHandler} />
            <Spacer x={0.5} />
            {!props.new && <Button onPress={saveHandler}>Save</Button>}
            {props.new && <Button onPress={createHandler}>Create</Button>}
          </Row>
        </Row>
      </Modal.Footer>
    </Modal>
  );
}
