import { Container, Spacer } from "@nextui-org/react";
import React from "react";
import SubNav, { Item } from "../navigation/subnav";
import { Outlet } from "react-router-dom";


export default function ProductionLayout() {
  const items: Array<Item> = [
    { name: "Overview", href: "/production" },
    { name: "Records", href: "/production/records" },
    { name: "Management", href: "/production/management" },
    { name: "Planning", href: "/production/planning" },
  ]

  return (
    <>
      <SubNav items={items} />
      <Spacer y={1} />
      <Container>
        <Outlet />
      </Container>
    </>
  )
}
