import React from 'react';
import {
  Navbar,
  Image,
} from "@nextui-org/react";
import AccountDropdown from "./account_dropdown";
import { Link, useLocation } from "react-router-dom";

const logo = new URL('../../assets/brand/logo.svg', import.meta.url).toString();
const icon = new URL('../../assets/brand/icon.svg', import.meta.url).toString();

export default function Navigation() {
  const location = useLocation();

  function isActive(href: string): boolean {
    if (href == "/") {
      return location.pathname == href
    } else {
      return location.pathname.startsWith(href)
    }
  }

  const items = [
    { name: "Home", href: "/" },
    // { name: "Sales", href: "/sales" },
    { name: "Purchasing", href: "/purchasing" },
    { name: "Inventory", href: "/inventory" },
    { name: "Production", href: "/production" },
    // { name: "Engineering", href: "/engineering" },
    // { name: "Analytics", href: "/analytics" },
  ]

  return (
    <Navbar
      shouldHideOnScroll
      variant="sticky"
      maxWidth="fluid"
      css={{ borderBottom: "1px solid var(--nextui-colors-accents1)", zIndex: 1000 }}
    >
      <Navbar.Toggle showIn="sm" />
      <Navbar.Brand hideIn="sm">
        <Image src={logo} height={50} />
      </Navbar.Brand>
      <Navbar.Brand showIn="sm">
        <Image src={icon} width={50} />
      </Navbar.Brand>
      <Navbar.Content hideIn="sm">
        {items.map((item) =>
          <Navbar.Item key={item.href} isActive={isActive(item.href)}>
            <Navbar.Link>
              <Link to={item.href} style={{ color: "inherit" }}>
                {item.name}
              </Link>
            </Navbar.Link>
          </Navbar.Item>
        )}
      </Navbar.Content>
      <Navbar.Collapse>
        {items.map((item) =>
          <Navbar.CollapseItem key={item.href} isActive={isActive(item.href)}>
            <Link to={item.href} style={{ color: "inherit" }}>
              {item.name}
            </Link>
          </Navbar.CollapseItem>
        )}
      </Navbar.Collapse>
      <AccountDropdown />
    </Navbar>
  );
}
