import React, { useEffect, useState } from "react";
import {
  Dropdown, Image, PressEvent, Text, useAsyncList, useInput,
} from "@nextui-org/react";
import supabase from "../../api/supabase";
import { Database } from "../../api/database.types";

type Country = Database["public"]["Tables"]["country"]["Row"];

interface Props {
  /**
   * Current country
   */
  country?: Country,
  /**
   * Callback when a country is selected
   */
  onSelectionChange?: (key: Selection) => void,
}

export default function CountryButton(props: Props) {
  const current = useInput("");

  const load = async () => {
    const { data: list, error } = await supabase
      .from('country')
      .select()
      .order("name");

    console.log(list);

    if (error) {
      console.log('error', error);
    } else {
      return { items: list };
    }
  };

  const list = useAsyncList({ load });

  return (
    <>
      <Text size={14} css={{ pb: "2px" }}>Country</Text>
      <Dropdown isBordered>
        <Dropdown.Button
          color="grey"
          css={{width: "20"}}
        >
          {props.country ? props.country?.name : "Not selected"}
        </Dropdown.Button>
        <Dropdown.Menu
          items={list.items}
          css={{ maxWidth: "420px" }}
          onSelectionChange={props.onSelectionChange}
          selectionMode="single"
          selectedKeys={new Set([props.country?.id])}
        >
          {(item) =>
            <Dropdown.Item
              key={item.id}
              icon={<Image width={20} src={`https://flagcdn.com/${item.iso2.toLowerCase()}.svg`} />}
              textValue={item.name}
            >
              {item.name}
            </Dropdown.Item>
          }
        </Dropdown.Menu>
      </Dropdown>
    </>

  )
}
