import React from 'react';
import {
  Text,
  Button,
  Input,
  Container,
  Card,
  Spacer,
  useInput,
  Grid,
  Image,
  Row,
} from "@nextui-org/react";
import { useNavigate } from "react-router-dom";
import supabase from '../../api/supabase';
import { PostgrestError } from '@supabase/supabase-js';
import { Call } from 'react-iconly';
const icon = new URL('../../assets/brand/icon.svg', import.meta.url).toString();

export default function OrganizationNew() {
  const name = useInput('');
  const phone = useInput('');
  const identifier = useInput('');

  const [errorText, setErrorText] = React.useState(String);

  const navigate = useNavigate();

  function setError(error: PostgrestError) {
    console.log(error.code)
    switch (error.code) {
      case "23505": setErrorText("An organization already exists with that identifier.\nPlease chose another."); break;
      default: setErrorText("Unkown error " + error.message);
    }
  }

  async function createOrganization() {
    const user_id = (await supabase.auth.getUser()).data.user?.id

    if (!user_id) {
      setErrorText("There was an issue getting your user details. Please login and try again.")
      return;
    }

    // add the organization
    const { data, error } = await supabase
      .from('organization')
      .insert({
        name: name.value,
        identifier: identifier.value,
        owner: user_id,
        metadata: {
          contact_phone: phone.value
        }
      })
      .select();

    if (!error) {
      await supabase.auth.updateUser({
        data: {
          current_org: data
        }
      });

      // get new token with updated metadata
      await supabase.auth.refreshSession();

      navigate("/"); // successful, lets go home
    }
    else {
      setError(error);
      console.error(error);
    }
  }

  return (
    <Grid.Container>
      <Image src={icon} css={{ zIndex: 0, position: 'fixed', top: "-25%", left: "-25%", minWidth: "800px", filter: 'opacity(10%)' }} />
      <Grid xs={0} sm={2} md={3} lg={4}></Grid>
      <Grid xs={12} sm={8} md={6} lg={4}>
        <Container>
          <Spacer y={4} />
          <Text h1>Optimize your business</Text>
          <Spacer y={4} />
          <Card
            css={{ mw: "500px" }}
          >
            <Card.Header>
              <Text h1 size={24} css={{ textAlign: "center", width: "$full" }}>Create your organization</Text>
            </Card.Header>
            <Card.Body>
              <Grid.Container gap={2}>
                <Grid xs={12}>
                  <Input {...name.bindings}
                    required
                    type="text"
                    label="Business Name"
                    placeholder="Unlimited Parts Company"
                    width="$full"
                  ></Input>
                </Grid>
                <Grid xs={12}>
                  <Input {...phone.bindings}
                    required
                    type="tel"
                    label="Contact Phone"
                    labelLeft={<Call size={18} />}
                    placeholder="491 570 159"
                    width="$full"
                  ></Input>
                </Grid>
                <Grid xs={12}>
                  <Input {...identifier.bindings}
                    required
                    type="text"
                    label="Identifier"
                    labelRight=".optimize.business"
                    placeholder="unlimited-parts-co"
                    width="$full"
                  ></Input>
                </Grid>
                {errorText && <Grid xs={12}>
                  <Text color="error">{errorText}</Text>
                </Grid>}
              </Grid.Container>
            </Card.Body>
            <Card.Footer>
              <Container gap={1}>
                <Row justify="flex-end">
                  <Button flat onPress={createOrganization}>Create Organization</Button>
                </Row>
                <Spacer y={0.5} />
              </Container>
            </Card.Footer>
          </Card>
        </Container>
      </Grid>
      <Grid xs={0} sm={2} md={3} lg={4}></Grid>
    </Grid.Container>
  )
}
