import React, { useEffect, useState } from 'react';
import {
  Table,
  Spacer,
  useAsyncList,
  Button,
  Grid,
  Link,
  Row,
} from "@nextui-org/react";
import supabase from "../../api/supabase";
import { Plus } from "react-iconly";
import Location from '../../components/modals/location';
import ColumnSelector, { Column } from '../../components/column_selector';
import { useUserMetadata } from '../../hooks/UserMetadata';
import { tableStyle } from '../../components/theme';

export default function Locations() {

  const [userMeta, setUserMeta] = useUserMetadata();
  const [LocationDialog, setLocationDialog] = useState(<></>);

  const columns: Column[] = [
    { name: "Location #", uid: "location_id", default: true },
    { name: "Location", uid: "location_name", default: true },
    { name: "Address", uid: "location_address", default: true },
  ]

  async function load() {

    const { data: list, error } = await supabase
      .from('location')
      .select()
      .neq('hidden', true)
      .order("location_id");

    list?.sort()

    if (error) console.error(error);
    else return {
      items: list,
    };
  }

  // rerender the table on auth change
  useEffect(() => {
    list.reload()
  }, [userMeta])


  const list = useAsyncList({ load });

  const openNewLocationDialog = () => {
    setLocationDialog(<Location new onClose={closeLocationDialog} />);
  }

  const editLocationDialog = (location_id: number) => {
    setLocationDialog(<Location location_id={location_id} onClose={closeLocationDialog} />);
  }

  const closeLocationDialog = () => {
    setLocationDialog(<></>);
  }



  return (
    <>
      {LocationDialog}
      <Grid.Container justify="space-between">
        <Grid>
          <Row align="center" gap={2}>
            <ColumnSelector columns={columns} />
          </Row>
        </Grid>
        <Grid>
          <Button auto light icon={<Plus />} onPress={openNewLocationDialog}>Add Location</Button>
        </Grid>
      </Grid.Container>
      <Spacer y={1} />
      <Table
        aria-label="Table containing list of Locations"
        selectionMode='single'
        {...tableStyle}
      >
        <Table.Header columns={columns}>
          {(column) => (
            <Table.Column width="25%" key={column.uid}>{column.name}</Table.Column>
          )}
        </Table.Header>
        <Table.Body
          items={list.items}
          loadingState={list.loadingState}
        >

          {(item) => (
            <Table.Row key={item.location_id}>
              {(key) => <Table.Cell><Link onPress={() => { editLocationDialog(item.location_id) }} color="text">{item[key]}</Link></Table.Cell>}
            </Table.Row>
          )}
        </Table.Body>
        <Table.Pagination
          align="center"
          rowsPerPage={10}
        />
      </Table>
    </>
  );
}
