import React, { useRef, useState } from 'react';
import {
  Badge,
  Button,
  Grid,
  Row,
  Spacer,
  Table,
  Text,
  useAsyncList,
} from "@nextui-org/react";
import supabase from '../../api/supabase';
import { Database } from '../../api/database.types';
import { Delete, PaperDownload, Plus } from 'react-iconly';
import ColumnSelector, { Column } from '../../components/column_selector';
import SupplierModal from '../../components/modals/supplier';
import { tableStyle } from '../../components/theme';

export default function Suppliers() {
  const [supplierModal, setSupplierModal] = useState(<></>);

  async function load() {
    const { data, error } = await supabase
      .from("supplier")
      .select()
      .order('name');

    if (error) console.error(error);
    else return {
      items: data,
    };
  }

  const list = useAsyncList({ load });

  const columns: Column[] = [
    { name: "Name", uid: "name", default: true } as Column,
    { name: "Properties", uid: "properties", default: true } as Column,
  ];

  const closeSupplierModal = () => {
    setSupplierModal(<></>);
    list.reload();
  }

  const openSupplierModal = (id: number) => {
    setSupplierModal(<SupplierModal id={id} onClose={closeSupplierModal} />);
  }

  const openNewSupplierModal = () => {
    setSupplierModal(<SupplierModal new onClose={closeSupplierModal} />);
  }

  return (
    <>
      {supplierModal}
      <Text h1>Suppliers</Text>
      <Grid.Container justify="space-between">
        <Grid>
          <Row align="center" gap={2}>
            <Badge color="error" content={0} isInvisible={true}>
              <Delete />
            </Badge>
            <Spacer x={1} />
            <Badge color="error" content={1} isInvisible={true}>
              <PaperDownload />
            </Badge>
            <Spacer x={1} />
            <ColumnSelector columns={columns} />
          </Row>
        </Grid>
        <Grid>
          <Button light auto icon={<Plus />} onPress={openNewSupplierModal}>Add supplier</Button>
        </Grid>
      </Grid.Container>
      <Spacer y={1} />
      <Table
        aria-label="Table containing list of suppliers"
        selectionMode="multiple"
        {...tableStyle}
      >
        <Table.Header columns={columns}>
          {(column) => (
            <Table.Column key={column.uid}>{column.name}</Table.Column>
          )}
        </Table.Header>
        <Table.Body
          items={list.items}
          loadingState={list.loadingState}
        >
          {(item) => (
            <Table.Row key={item.uid as React.Key}>
              {(key) => (
                <Table.Cell>list[item.uid]</Table.Cell>
              )}
            </Table.Row>
          )}
        </Table.Body>
        <Table.Pagination
          align="center"
          rowsPerPage={10}
        />
      </Table>
    </>
  );
}
