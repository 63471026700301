import React from 'react';
import { Card, Container, Spacer, Text } from "@nextui-org/react";

export default function Home() {
  return (
    <Container>
      <Spacer y={1} />
      <Card
        css={{ bg: "var(--nextui-colors-primaryLight)", maxWidth: "500px" }}
      >
        <Card.Body
          css={{ px: "1.5em" }}
        >
          <Text b color="primary">Looking a little empty?</Text>
          <Spacer y={0.5} />
          <Text color="p" css={{ lineHeight: "1.2" }}>That's okay, we're just getting started and will be adding more content soon.</Text>
        </Card.Body>
      </Card>
    </Container>
  );
}
