import { Button, Modal, Spacer, Text } from "@nextui-org/react";
import React from "react";
import { People, Plus } from "react-iconly";
import { useNavigate } from "react-router-dom";


export default function NewUser() {
  const navigate = useNavigate();

  return (
    <Modal
      open={true}
      preventClose
      width="25rem"
      blur
    >
      <Modal.Header>
        <Text h2>Let's Get Started</Text>
      </Modal.Header>
      <Modal.Body>
        <Text>But before we do, are you new here or looking for someone else?</Text>
      </Modal.Body>
      <Modal.Footer css={{ flexDirection: "column" }}>
        <Button
          auto
          flat
          color="secondary"
          icon={<Plus />}
          onPress={() => navigate("/organization/create")}
        >
          Create an Organization
        </Button>
        <Button
          auto
          flat
          onPress={() => navigate("/organization/join")}
          icon={<People />}
        >
          Join an Organization
        </Button>
        <Spacer y={0.5} />
      </Modal.Footer>
    </Modal>
  )
}
