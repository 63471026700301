import React from 'react';
import {
  Dropdown,
} from "@nextui-org/react";

export interface Column {
  name: string,
  uid: string,
  default?: boolean
}

interface Props {
  columns: Column[],
  onSelectionChange?: ((keys: any) => any) | undefined,
}

export default function ColumnSelector(props: Props) {
  const default_columns = props.columns
    .filter(column => column.default)
    .map(column => column.uid);

  return (
    <Dropdown
      placement="bottom"
      isBordered
    >
      <Dropdown.Button light>Columns</Dropdown.Button>
      <Dropdown.Menu
        selectionMode="multiple"
        items={props.columns}
        defaultSelectedKeys={default_columns}
        onSelectionChange={props.onSelectionChange}
      >
        {(column) => (
          <Dropdown.Item key={column.uid}>{column.name}</Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}
