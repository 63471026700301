import React, { useEffect, useState } from 'react';
import {
  Table,
  Spacer,
  Text,
  useAsyncList,
  Button,
  Grid,
  Link,
} from "@nextui-org/react";
import supabase from "../../api/supabase";
import { Plus, Category } from "react-iconly";
import Part from '../../components/modals/part';
import ColumnSelector, { Column } from '../../components/column_selector';
import { useUserMetadata } from '../../hooks/UserMetadata';
import { useParams, useNavigate } from 'react-router-dom';
import { Modal } from '@nextui-org/react';
import FilterBuilder from '../../components/filter_builder';
import { tableStyle } from '../../components/theme';


export default function PartLibrary() {
  const [userMeta, setUserMeta] = useUserMetadata();
  const [partDialog, setPartDialog] = useState(<></>);

  const icon = {
    'component': <Category />,
    'assmbly': <Category set="bold" />,
  };

  const columns: Column[] = [
    { name: "Part", uid: "part_name", default: true },
    { name: "Part #", uid: "part_number", default: true },
    { name: "Description", uid: "part_description", default: true },
  ]

  const default_columns = ["name", "number"]

  async function load() {

    const { data: list, error } = await supabase
      .from('part')
      .select()
      .eq('organization_id', userMeta?.current_org?.id)
      .order("part_number");

    list?.sort()

    if (error) console.error(error);
    else return {
      items: list,
    };
  }

  const list = useAsyncList({ load });

  // rerender the table on auth change
  useEffect(() => {
    list.reload()
  }, [userMeta])

  // If a part number has been supplied in the url, open it's modal
  const { urlPartNumber } = useParams()

  useEffect(() => {
    // Check a part number was provided
    if (!urlPartNumber) return;

    let numericPartNumber = parseInt(urlPartNumber);
    supabase
      .from('part')
      .select()
      .eq('part_number', numericPartNumber)
      .then((result) => {
        // If part exists open model
        if (result.data && result.data?.length > 0) openPartDialog(numericPartNumber);
        else {
          // Invalid part number, show error modal
          setPartDialog(<>
            <Modal
              closeButton
              aria-labelledby="Part not found"
              open={true}
              onClose={closePartDialog}
            >
              <Modal.Header>
                <Text size={18}>
                  <Text b size={18}>
                    Error:
                  </Text>
                  <a> </a>
                  it looks like this part doesn't exists.
                </Text>
              </Modal.Header>
              <Modal.Footer>
                <Button auto onPress={closePartDialog}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </>);
        }
      })
  }, [urlPartNumber]);

  const navigate = useNavigate()

  const closePartDialog = () => {
    navigate('/inventory/parts');
    setPartDialog(<></>);
  }

  const openPartDialog = (part_number: number) => {
    navigate('/inventory/parts/' + part_number);
    setPartDialog(<Part part_number={part_number} onClose={closePartDialog} />);
  }

  const openNewPartDialog = () => {
    setPartDialog(<Part new onClose={closePartDialog} />);
  }

  return (
    <>
      {partDialog}
      <Grid.Container justify="space-between">
        <Grid xs={6} sm={10}>
          <FilterBuilder />
        </Grid>
        <Grid xs={6} sm={2} justify="flex-end">
          <Button auto light icon={<Plus />} onPress={openNewPartDialog}>New Part</Button>
        </Grid>
      </Grid.Container>
      <Spacer y={1} />
      <Table
        aria-label="Table containing list of parts"
        selectionMode="multiple"
        {...tableStyle}
      >
        <Table.Header columns={columns}>
          {(column) => (
            <Table.Column width="25%" key={column.uid}>{column.name}</Table.Column>
          )}
        </Table.Header>
        <Table.Body
          items={list.items}
          loadingState={list.loadingState}
        >
          {(item) => (
            <Table.Row key={item.part_number}>
              {(key) => <Table.Cell><Link color="text" onPress={() => openPartDialog(item.part_number)}>{item[key]}</Link></Table.Cell>}
            </Table.Row>
          )}
        </Table.Body>
        <Table.Pagination
          align="center"
          rowsPerPage={10}
        />
      </Table>
    </>
  );
}
