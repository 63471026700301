import { Button, Container, Input, NormalColors, Popover, Row, Spacer, Text } from "@nextui-org/react";
import React, { useEffect, useState } from 'react';
import { Category, Filter2, Search, Show, User, Work } from "react-iconly";
import supabase from "../api/supabase";

type Operand = "is" | "is not" | "more than" | "less than" | "before" | "after"
type Colors = NormalColors | "grey"
type Group = "company" | "inventory" | "user" | "visibility"

interface FilterTemplate {
  uid: string,
  name: string,
  operands: Operand[],
}

interface FilterGroup {
  uid: Group,
  icon: React.ReactElement,
  color: Colors,
  templates: Array<FilterTemplate>
}

interface Filter {
  group: Group
  template: FilterTemplate,
  operand: Operand,
  subject: number | number[] | string | string[],
}

/**
  Interactively create filters for database queries.
*/
export default function FilterBuilder() {
  const filters: FilterGroup[] = [
    {
      uid: "company",
      icon: <Work />,
      color: "primary",
      templates: [
        { uid: "supplier", name: "Supplier", operands: ["is", "is not"] },
        { uid: "manufacturer", name: "Manufacturer", operands: ["is", "is not"] },
      ]
    },
    {
      uid: "inventory",
      icon: <Category />,
      color: "secondary",
      templates: [
        { uid: "stock_location", name: "Stock Location", operands: ["is", "is not"] },
        { uid: "quantity", name: "Quantity", operands: ["more than", "less than"] },
      ]
    },
    {
      uid: "user",
      icon: <User />,
      color: "warning",
      templates: [
        { uid: "creator", name: "Creator", operands: ["is", "is not"] },
        { uid: "editor", name: "Editor", operands: ["is", "is not"] },
      ]
    },
    {
      uid: "visibility",
      icon: <Show />,
      color: "grey",
      templates: [
        { uid: "status", name: "Status", operands: ["is", "is not"] },
      ]
    }
  ]

  const [activeFilters, setActiveFilters] = useState<Filter[]>([])

  const removeFilter = (index: number) => {
    const f = activeFilters
    f.splice(index, 1) // remove nth element
    setActiveFilters(f)
    console.log(activeFilters.length)
  }

  useEffect(() => {
    setActiveFilters([
      {
        group: filters[0].uid,
        template: filters[0].templates[0],
        operand: "is",
        subject: "Mouser"
      },
      {
        group: filters[3].uid,
        template: filters[3].templates[0],
        operand: "is",
        subject: "Active"
      },
    ])
  }, [])

  return (
    <Row align="center" wrap="wrap">
      <Popover>
        <Popover.Trigger>
          <Button auto light icon={<Filter2 />}>Add</Button>
        </Popover.Trigger>
        <Popover.Content css={{ border: "1px solid var(--nextui-colors-accents0)" }}>
          <Container css={{ p: "0.5em", minWidth: 350, maxWidth: 450 }}>
            <Input
              fullWidth
              clearable
              bordered
              size="sm"
              borderWeight="light"
              animated={false}
              labelLeft={<Search size="small" />}
              placeholder="Search"
            />
            <Spacer y={0.5} />
            <Container gap={0.5}>
              <Text color="var(--nextui-colors-accents8)" size="small">Filter by</Text>
              <Spacer y={0.5} />
              {filters.map((f) =>
                <Row align="flex-start" css={{ mb: "0.5em" }}>
                  {f.icon}
                  <Spacer x={0.5} />
                  <Row justify="flex-start" wrap="wrap">
                    {f.templates.map((i) =>
                      <Button
                        key={i.uid}
                        auto
                        flat
                        size="xs"
                        color={f.color}
                        css={{ m: "0.1em" }}
                      >
                        {i.name}
                      </Button>
                    )}
                  </Row>
                </Row>
              )}
            </Container>
          </Container>
        </Popover.Content>
      </Popover>
      {activeFilters.map((f, i) =>
        <Button.Group flat color={filters.find((g) => g.uid == f.group)?.color as NormalColors} size="sm">
          <Button css={{ pl: "1em", pr: "0.5em", color: "black" }} rounded>{f.template.name}</Button>
          <Button css={{ p: "0.5em" }} animated={false}>{f.operand}</Button>
          <Button css={{ pl: "0.5em", pr: "0.5em", color: "black" }}>{f.subject}</Button>
          <Button css={{ p: "0.9em", pr: "1em" }} rounded onPress={() => removeFilter(i)}>×</Button>
        </Button.Group>
      )}
    </Row>
  )
}

function convertToSupabaseQuery(filter: Filter) {
  const operand = filter.operand
  const subject = filter.subject
  const column = filter.template.uid

  let query = supabase.from("part")
    .select("*")

  if (operand === 'is') {
    if (Array.isArray(subject)) {
      query.in(column, filter.subject as string[] | number[])
    } else {
      query.eq(column, filter.subject)
    }
  } else if (operand === 'is not') {
    if (Array.isArray(subject)) {
      query.in(column, filter.subject as string[])
    } else {
      query.eq(column, filter.subject)
    }
  } else if (operand === 'more than') {
    query = query.gt(column, filter.subject)
  } else if (operand === 'less than') {
    query = query.lt(column, filter.subject)
  }

  return query;
}
