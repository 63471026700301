import React, { useEffect, useState } from 'react';
import {
  Text,
  Button,
  Input,
  Container,
  Card,
  Spacer,
  useInput,
  Grid,
  Link,
  Image,
  Row,
  Loading,
} from "@nextui-org/react";
import supabase from '../api/supabase';
import { NavLink } from 'react-router-dom';
import { ArrowLeft, Message } from 'react-iconly';

const icon = new URL('../assets/brand/icon.svg', import.meta.url).toString();

export default function SignIn() {
  const [loading, setLoading] = useState(false)
  const name = useInput('');
  const email = useInput('');
  const password = useInput('');

  const [errorText, setErrorText] = useState(String);
  const [complete, setComplete] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);

  async function createUser() {
    setLoading(true)

    const { error } = await supabase.auth.signUp({
      email: email.value,
      password: password.value,
      options: {
        data: {
          name: name.value,
        }
      }
    })

    if (error) setErrorText(error.message);
    else setComplete(true)

    setLoading(false)
  }

  const headingStlye = { textAlign: "center", width: "$full", pt: "0.5em" };

  useEffect(() => {
    const getLoggedIn = async () => {
      const { data } = await supabase.auth.getSession()

      if (data?.session) {
        setLoggedIn(true)
      }
    }

    getLoggedIn()
  }, []);

  return (
    <Grid.Container>
      <Image src={icon} css={{ zIndex: 0, position: 'fixed', top: "-25%", left: "-25%", minWidth: "800px", filter: 'opacity(10%)' }} />
      <Grid xs={0} sm={2} md={3} lg={4}></Grid>
      <Grid xs={12} sm={8} md={6} lg={4}>
        <Container>
          <Spacer y={4} />
          { /* todo: put a &trade; after Optimize once we have the trademark */}
          <Text h1 css={{ lineHeight: 1.0 }}>Get ready to Optimize<br />your business</Text>
          <Spacer y={4} />
          {(loggedIn && !complete) && <>
            <Card variant="flat" css={{ background: "$warningLight", mt: 20 }}>
              <Card.Body css={{ px: 20 }}>
                <Text color="warning">Are you sure? You are already logged in.</Text>
                <Text color="warning">Return <NavLink to="/" style={{ color: "inherit", fontWeight: "bold" }}>home</NavLink>.</Text>
              </Card.Body>
            </Card>
            <Spacer y={1} />
          </>}
          <Card>
            {!complete ? <>
              <Card.Header>
                <Text h2 size={24} css={headingStlye}>Get started by creating your account</Text>
              </Card.Header>
              <Card.Body>
                <Grid.Container gap={2}>
                  <Grid xs={12}>
                    <Input {...name.bindings} label="Full name" required width="$full"></Input>
                  </Grid>
                  <Grid xs={12}>
                    <Input {...email.bindings} label="Email" required width="$full" labelLeft={<Message />}></Input>
                  </Grid>
                  <Grid xs={12}>
                    <Input.Password {...password.bindings} label="Password" required width="$full"></Input.Password>
                  </Grid>
                  {errorText && <Grid xs={12}>
                    <Card variant="flat" css={{ background: "$errorLight", mt: 20 }}>
                      <Card.Body css={{ px: 20 }}>
                        <Text color="error" weight="bold">{errorText}.</Text>
                        <Text color="error">Please try again.</Text>
                      </Card.Body>
                    </Card>
                  </Grid>}
                </Grid.Container>
              </Card.Body>
              <Card.Footer>
                <Container gap={1}>
                  <Row justify="space-between" align="flex-end">
                    <Link href="/">
                      <Row align="center">
                        <ArrowLeft />
                        Back to sign in
                      </Row>
                    </Link>
                    <Button onPress={createUser} flat>
                      {loading ?
                        <Loading size="sm" />
                        :
                        "Create Your Account"
                      }
                    </Button>
                  </Row>
                  <Spacer y={0.5} />
                </Container>
              </Card.Footer>
            </> : <>
              <Card.Header>
                <Text h1 size={24} css={headingStlye}>Thank you</Text>
              </Card.Header>
              <Card.Body>
                <Container>
                  <Text>Please check your email and follow the instructions to complete signing up.</Text>
                </Container>
                <Spacer y={0.5} />
              </Card.Body>
            </>}
          </Card>
        </Container>
      </Grid>
      <Grid xs={0} sm={2} md={3} lg={4}></Grid>
    </Grid.Container>
  )
}
