import React, { useMemo, useState } from "react";
import { Outlet } from "react-router-dom";
import { Spacer } from "@nextui-org/react";
import supabase from '../api/supabase';
import Navigation from "../components/navigation/main";
import Login from "../components/modals/login";
import NewUser from "../components/modals/new_user";
import Footer from "../components/layout/footer";

export default function Root() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isNew, setIsNew] = useState(false);

  supabase.auth.getSession().then((res) => {
    setIsLoggedIn(res.data.session != null);
  })

  useMemo(() => {
    supabase.auth.getUser().then(({ data }) => {
      setIsNew(!data?.user?.user_metadata?.current_org);
    });
  }, []);

  return (
    <>
      <Navigation />
      <Spacer y={1} />
      <Outlet />
      <Spacer y={2} />
      <Footer />
      {!isLoggedIn && <Login />}
      {(isLoggedIn && isNew) && <NewUser />}
    </>
  );
}
