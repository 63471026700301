import { Container, Spacer } from "@nextui-org/react";
import React from "react";
import SubNav, { Item } from "../navigation/subnav";
import { Outlet } from "react-router-dom";

export default function AnalyticsLayout() {
  const items: Array<Item> = [
    { name: "Overview", href: "/analytics" },
    { name: "Reports", href: "/analytics/reports" },
    { name: "Business", href: "/analytics/business" },
    { name: "Financial", href: "/analytics/financial" },
  ]

  return (
    <>
      <SubNav items={items} />
      <Spacer y={1} />
      <Container>
        <Outlet />
      </Container>
    </>
  )
}
