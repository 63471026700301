import React from 'react';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Root from './pages/root';
import PartLibrary from "./pages/inventory/parts";
import Stock from "./pages/inventory/stock";
import Locations from "./pages/inventory/locations";
import Suppliers from "./pages/purchasing/suppliers";
import SignUp from "./pages/signup";
import InventoryOverview from './pages/inventory/overview';
import Home from './pages/home';
import PurchasingLayout from './components/layout/purchasing';
import Overview from './pages/purchasing/oveview';
import InventoryLayout from './components/layout/inventory';
import ProductionLayout from './components/layout/production';
import SalesLayout from './components/layout/sales';
import EngineeringLayout from './components/layout/engineering';
import AnalyticsLayout from './components/layout/analytics';
import OrganizationSettingsLayout from './components/layout/organizationSettings'
import OrganizationSettings from './pages/organization/settings';
import OrganizationUserSettings from './pages/organization/users';
import OrganizationCreate from './pages/organization/create';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "sales",
        element: <SalesLayout />,
        children: [
          { path: "", element: <></> },
        ]
      },
      {
        path: "purchasing",
        element: <PurchasingLayout />,
        children: [
          { path: "", element: <Overview /> },
          { path: "orders", element: <></> },
          { path: "suppliers", element: <Suppliers /> },
        ],
      },
      {
        path: "inventory",
        element: <InventoryLayout />,
        children: [
          { path: "", element: <InventoryOverview /> },
          { path: "parts/:urlPartNumber", element: <PartLibrary /> },
          { path: "parts", element: <PartLibrary /> },
          { path: "stock", element: <Stock /> },
          { path: "locations", element: <Locations /> }
        ],
      },
      {
        path: "production",
        element: <ProductionLayout />,
        children: [
          { path: "", element: <></> },
          { path: "records", element: <></> },
          { path: "management", element: <></> },
          { path: "planning", element: <></> },
        ]
      },
      {
        path: "engineering",
        element: <EngineeringLayout />,
        children: [
          { path: "", element: <></> },
        ]
      },
      {
        path: "analytics",
        element: <AnalyticsLayout />,
        children: [
          { path: "", element: <></> },
          { path: "reports", element: <></> },
          { path: "business", element: <></> },
          { path: "financial", element: <></> },
        ]
      },
      {
        path: "organization/settings",
        element: <OrganizationSettingsLayout />,
        children: [
          { path: "", element: <OrganizationSettings /> },
          { path: "users", element: <OrganizationUserSettings /> },
          { path: "billing", element: <OrganizationSettings /> }
        ]
      },
    ],
  },
  {
    path: "signup",
    element: <SignUp />
  },
  {
    path: "organization/create",
    element: <OrganizationCreate />,
  },
]);

export default function Router() {
  return (
    <RouterProvider router={router} />
  );
}
