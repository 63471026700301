import { Grid, Card, Text, Input, Button, Container, Image, Dropdown, useModal, Modal } from "@nextui-org/react";
import React from "react";
import { CloseSquare, Search, Message } from "react-iconly";

const logo = new URL('../../assets/brand/icon.svg', import.meta.url).toString();

const junkUserData = [
    { id: 1, name: "user 1", email: "user1@example.com", role: "Administrator" },
    { id: 2, name: "user 2", email: "user2@example.com", role: "Administrator" },
    { id: 3, name: "user 3", email: "user3@example.com", role: "Administrator" },
    { id: 4, name: "user 4", email: "user4@example.com", role: "Administrator" },
    { id: 5, name: "user 5", email: "user5@example.com", role: "Administrator" },
    { id: 6, name: "user 6", email: "user6@example.com", role: "Administrator" },
    { id: 7, name: "user 7", email: "user7@example.com", role: "Administrator" },
]

const roles = [
    { name: "Super Administrator", description: "All the permissions of Administrator plus ability to access billing and danger-zone settings." },
    { name: "Administrator", description: "Grants the user permissions to access all functionality of Optimize" },
    { name: "Engineering", description: "Grants access to the engineering " },
    { name: "Sales", description: "Grants the user permissions to access all functionality of Optimize" },
    { name: "Line manager", description: "Grants the user permissions to access all functionality of Optimize" },
    { name: "Custom", description: "A custom access policy you've created." },
]

/*
Note: This is purely a frontend design
It has not been hooked up to the backend in any way.
TODO: Connect this to some meaningful backend
*/
export default function OrganizationUserSettings() {

    const { setVisible, bindings } = useModal();

    /* Inspiration: https://dribbble.com/shots/18472638-Dark-Dashboard-Exploration-01 */
    return (
        <>
            <Grid.Container gap={2} justify="center">
                <SectionHeader title="Users" description="These are the users in your organization. You may modify their permissions, remove them from your organization, or invite new users.">
                    <Button css={{ marginTop: "1rem", marginBottom: "1rem" }} auto shadow color="primary" onPress={() => setVisible(true)}>
                        Invite users
                    </Button>
                </SectionHeader>
                <Grid xs={12} md={6}>
                    <UserManagementCard />
                </Grid>

                <Card.Divider css={{
                    margin: "2rem",
                }} />

                <SectionHeader title="Pending invites" description="These are the users who you have invited to your organization but haven't yet accepted their invitation." />
                <Grid xs={12} md={6}>
                    <UserInvitemodal/>
                </Grid>

            </Grid.Container>

            {/* Invite users modal */}
            <UserInvitationModal bindings={bindings} setVisible={setVisible} />
        </>
    );
}

const SectionHeader: React.FC<{title: string, description: string, children?: JSX.Element}> = ({title, description, children}) => (
    <Grid xs={12} md={4}>
        <div>
            <Text h2>{title}</Text>
            <Text>{description}</Text>
            {children}
        </div>
    </Grid>
)

const UserManagementCard = () => {
    return (
        <Card variant="flat">
            <Container alignItems="center">
                <Card.Header >
                    <Input
                        
                        css={{width: "100%"}}
                        contentRightStyling={false}
                        placeholder="Search Users"
                        contentRight={
                            <Button auto>
                                <Search set="light" primaryColor="black" />
                            </Button>
                        }
                    />
                </Card.Header>
            </Container>
            <Card.Body css={{ maxHeight: "30rem", marginTop: "0px"}}>
                {junkUserData.map((user) => (
                    <UserInfoItem 
                        name={user.name} 
                        email={user.email} 
                        logo={logo} 
                        roles={roles}
                    />
                ))}
            </Card.Body>
        </Card>
    );
}

const UserInfoItem: React.FC<{name: string, email: string, logo: string, roles: {name: string, description: string}[]}> = ({name, email, logo, roles}) => (
    <div>
        <Card.Divider />
        <Grid.Container gap={1}>
            <Grid xs={2} >
                <Image src={logo} height={50} />
            </Grid>
            <Grid xs={5}>
                <div>
                    <Text h4 b css={{ marginBottom: "0px" }}>{name}</Text>
                    <Text>{email}</Text>
                </div>
            </Grid>
            <Grid xs={4}>
                <UserRoleDropdown roles={roles} />
            </Grid>
            <Grid xs={1}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <CloseSquare set="bold" primaryColor="grey" />
                </div>
            </Grid>
        </Grid.Container>
    </div>
)

const UserRoleDropdown: React.FC<{roles: {name: string, description: string}[]}> = ({roles}) => (
    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        <Dropdown>
            <Dropdown.Button color="primary" solid>
                Administrator
            </Dropdown.Button>
            <Dropdown.Menu aria-label="Roles">
                {roles.map((role) =>
                    <Dropdown.Item
                        showFullDescription
                        key={role.name}
                        description={role.description}
                    >
                        {role.name}
                    </Dropdown.Item>
                )}
            </Dropdown.Menu>
        </Dropdown>
    </div>
)

const UserInvitemodal = () => {
    return (
        <Card variant="flat">
            <Card.Body css={{ maxHeight: "30rem" }}>
                {junkUserData.map((user) => (
                    <InviteItem email={user.email} />
                ))}
            </Card.Body>
        </Card>
    );
}

const InviteItem: React.FC<{email: string}> = ({email}) => (
    <div>
        <Grid.Container gap={1}>
            <Grid xs={11}>
                <div>
                    <Text h4>{email}</Text>
                </div>
            </Grid>
            <Grid xs={1}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <CloseSquare set="bold" primaryColor="grey" />
                </div>
            </Grid>
        </Grid.Container>
    </div>
)

const UserInvitationModal: React.FC<{bindings: any, setVisible: (visible: boolean) => void}> = ({bindings, setVisible}) => (
    <div>
        <Modal
            scroll
            width="600px"
            aria-labelledby="modal-title"
            aria-describedby="modal-title"
            {...bindings}
        >
            <Modal.Header>
                <Text id="modal-title" size={18}>
                    Invite a user
                </Text>
            </Modal.Header>
            <Modal.Body>
                <Input
                    label="Email"
                    clearable
                    bordered
                    fullWidth
                    color="primary"
                    size="lg"
                    placeholder="Email"
                    contentLeft={<Message />}
                />

                <Text color="primary">User Role:</Text>
                <UserRoleDropdown roles={roles} />
            </Modal.Body>
            <Modal.Footer>
                <Button auto flat color="error" onPress={() => setVisible(false)}>
                    Close
                </Button>
                <Button auto onPress={() => setVisible(false)}>
                    Invite
                </Button>
            </Modal.Footer>
        </Modal>
    </div>
)