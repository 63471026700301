import React, { createContext, useContext, useEffect, useState } from "react";
import { Session, User } from "@supabase/supabase-js";
import supabase from "../api/supabase";

const AuthContext = createContext<{
  session: Session | null | undefined,
  user: User | null | undefined,
  signOut: () => void
}>({
  session: null,
  user: null,
  signOut: () => { }
});

export const AuthProvider = ({ children }: any) => {
  const [user, setUser] = useState<User>()
  const [session, setSession] = useState<Session | null>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // load user session data
    const setData = async () => {
      const { data: { session }, error } = await supabase.auth.getSession();
      if (error) throw error;
      setSession(session)
      setUser(session?.user)
      setLoading(false)
    }

    // listen for state changes
    const { data: listener } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session)
      setUser(session?.user)
      setLoading(false)
    })

    setData()

    return () => {
      // cleanup subscription
      listener?.subscription.unsubscribe()
    }
  }, [])

  // provider value
  const value = {
    session,
    user,
    signOut: async () => await supabase.auth.signOut(),
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}

/**
 * Creates an auth hook.
 * 
 * @returns Auth context
 */
export const useAuth = () => {
  return useContext(AuthContext);
}
