import React from 'react';
import { createTheme, NextUIProvider } from "@nextui-org/react";
import Router from "./router";
import { AuthProvider } from './hooks/Auth';
import { UserMetadataProvider } from './hooks/UserMetadata';
import ReactDOM from 'react-dom/client'

const theme = createTheme({
  type: "light",
  theme: {
    colors: {
      border: "var(--nextui-colors-accents0)",
    }
  }
})

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  <React.StrictMode>
    <NextUIProvider theme={theme}>
      <AuthProvider>
        <UserMetadataProvider>
          <Router />
        </UserMetadataProvider>
      </AuthProvider>
    </NextUIProvider>
  </React.StrictMode>
)
