import React from "react";
import { Container, Row, Col, Spacer, Text, Link } from "@nextui-org/react";
import { Document, InfoCircle } from "react-iconly";

export default function Footer() {
  return (
    <Container>
      <Col css={{ color: "var(--nextui-colors-accents6)" }}>
        <Link color="inherit" href="https://docs.optimize.business" target="_blank" isExternal>
          <Row align="center">
            <Document size={18} />
            <Spacer x={0.25} />
            <Text b color="inherit" size="small">Help & Support</Text>
          </Row>
        </Link>
        <Spacer y={0.5} />
        <Link color="inherit" href="https://status.optimize.business" target="_blank" isExternal>
          <Row align="center">
            <InfoCircle size={18} />
            <Spacer x={0.25} />
            <Text b color="inherit" size="small">Status</Text>
          </Row>
        </Link>
      </Col>
    </Container>
  )
}
