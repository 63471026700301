import { createClient } from '@supabase/supabase-js';
import { Database } from './database.types';

const supabase = createClient<Database>(
  process.env.SUPABASE_URL as string,
  process.env.SUPABASE_ANON_KEY as string,
)

export default supabase;

export interface UserMetadata {
  name: string | null | undefined,
  current_org: Database["public"]["Tables"]["organization"]["Row"] | null | undefined,
}

export interface SupplierContact {
  name: string,
  phone: string,
  email: string,
}
