import { Container, Navbar } from "@nextui-org/react";
import React from "react";
import { Link, useLocation } from "react-router-dom";

export type Item = {
  name: string,
  href: string,
}

export default function SubNav({ items }: {
  items: Array<Item>,
}) {
  const location = useLocation();

  function isActive(href: string): boolean {
    return location.pathname == href
  }

  return (
    <Container>
      <Navbar disableShadow containerCss={{ zIndex: 0, px: 0 }} maxWidth="fluid">
        <Navbar.Content>
          {items.map((item) =>
            <Navbar.Item key={item.href} variant="underline-rounded" isActive={isActive(item.href)}>
              <Link to={item.href} style={{ color: "black" }}>
                {item.name}
              </Link>
            </Navbar.Item>
          )}
        </Navbar.Content>
      </Navbar>
    </Container>
  )
}
