import { Container, Spacer } from "@nextui-org/react";
import React from "react";
import SubNav, { Item } from "../navigation/subnav";
import { Outlet } from "react-router-dom";

export default function PurchasingLayout() {
  const items: Array<Item> = [
    { name: "Overview", href: "/purchasing" },
    { name: "Purchase Orders", href: "/purchasing/orders" },
    { name: "Suppliers", href: "/purchasing/suppliers" },
  ]

  return (
    <>
      <SubNav items={items} />
      <Spacer y={1} />
      <Container>
        <Outlet />
      </Container>
    </>
  )
}
