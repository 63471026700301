import React from 'react';
import {
  Text,
} from "@nextui-org/react";

export default function Stock() {

  return (
    <>
      <Text h1>Stock</Text>
    </>
  );
}
