import React from "react";
import {
  Button, PressEvent,
} from "@nextui-org/react";

interface Props {
  /**
   * True if the cancelation callback needs to be confirmed by the user.
   * Defaults to `true`.
   */
  confirm?: boolean,
  /**
   * Handler that is called when the press is released over the target.
   */
  onPress?: (e: PressEvent) => void,
}

export default function CancelButton(props: Props) {
  const timeout = 2500; // milliseconds
  let confirmed = false || props.confirm === false;

  const onPress = (e: PressEvent) => {
    if (confirmed) {
      props.onPress && props.onPress(e);
    } else {
      confirmed = true;
      e.target.innerHTML = "Are you sure?";

      setTimeout(() => {
        e.target.innerHTML = "Cancel";
        confirmed = false;
      }, timeout);
    }
  }

  return (
    <Button flat auto color="error" onPress={onPress}>Cancel</Button>
  )
}
