import React, { useRef, useEffect, Dispatch, SetStateAction, useState } from "react";
import {
  Modal,
  Text,
  Textarea,
  Grid,
  Input,
  useInput,
  Button,
  PressEvent,
  Container,
  Spacer,
  Row,
  Dropdown,
  FormElement,
  useModal,
} from "@nextui-org/react";
import supabase from "../../api/supabase";
import { Delete } from "react-iconly";
import CancelButton from "../input/cancel";
import CountryButton from "../input/country";

interface Props {
  id?: number,
  new?: boolean,
  open?: boolean,
  onClose?: () => void,
}

export default function SupplierModal(props: Props) {
  const name = useInput("");
  const [country, setCountry] = useState(undefined);
  const notes = useInput("");
  const [timestampCreated, setTimestampCreated] = useState(new Date());
  const [timestampModified, setTimestampModified] = useState(new Date());
  const [errorText, setErrorText] = useState('');
  const { setVisible, bindings } = useModal(true);

  useEffect(() => {
    if (!props.new) fetchSupplier();
  }, []);

  const fetchSupplier = async () => {
    const { data, error } = await supabase
      .from('supplier')
      .select()
      .eq('id', props.id)
      .single();
    if (error) console.log('error', error);
    else {
      name.setValue(data.name);
      notes.setValue(data.notes || "");
      setTimestampCreated(new Date(data.created_at));
      setTimestampModified(new Date(data.updated_at));
    }
  };

  const saveHandler = async () => {
    const { error } = await supabase
      .from('supplier')
      .update({
        name: name.value,
        notes: notes.value,
      })
      .eq('id', props.id);

    if (error == null) {
      props.onClose && props.onClose();
    } else {
      setErrorText(error.message);
    }
  }

  const createHandler = async () => {
    const { data, error } = await supabase
      .from('supplier')
      .insert({
        name: name.value,
        notes: notes.value,
        organization: 2,
      });

    if (error == null) {
      props.onClose && props.onClose();
      setVisible(false);
    } else {
      setErrorText(error.message);
    }
  }

  let confirm = false;

  const cancelHandler = (event: PressEvent) => {
    if (!confirm && !props.new) {
      confirm = true;
      event.target.innerHTML = "Are you sure?";
    } else {
      props.onClose && props.onClose();
      setVisible(false);
    }
  }

  const deleteHandler = async () => {
    const { error } = await supabase
      .from('parts')
      .delete()
      .eq('id', props.id);

    if (error == null) {
      props.onClose && props.onClose();
      setVisible(false);
    } else {
      setErrorText(error.message);
    }
  }

  return (
    <Modal
      {...bindings}
      preventClose
      width="90%"
      css={{ mw: "800px", margin: "auto" }}
    >
      <Modal.Header>
        {!props.new && <Text h3>Edit supplier</Text>}
        {props.new && <Text h3>Add supplier</Text>}
      </Modal.Header>
      <Modal.Body>
        <Grid.Container gap={1}>
          <Grid xs={8}><Input {...name.bindings} width="$full" label="Name"></Input></Grid>
          <Grid xs={4} direction="column">
            <CountryButton onSelectionChange={(set) => setCountry(Array.from(set)[0])} country={country} />
          </Grid>
          <Grid xs={12}><Text>Contact</Text></Grid>
          <Grid xs={6}><Input width="$full" label="Name"></Input></Grid>
          <Grid xs={6}><Input width="$full" label="Position"></Input></Grid>
          <Grid xs={6}><Input width="$full" label="Email"></Input></Grid>
          <Grid xs={6}><Input width="$full" label="Phone"></Input></Grid>
          <Grid xs={12}><Text>Payment</Text></Grid>
          <Grid xs={4}><Input width="$full" label="Terms"></Input></Grid>
          <Grid xs={4}><Input width="$full" label="Method"></Input></Grid>
          <Grid xs={4}><Input width="$full" label="Deposit"></Input></Grid>
          <Grid xs={4}><Input width="$full" label="Tax"></Input></Grid>
          <Grid xs={12}><Textarea width="$full" label="Address"></Textarea></Grid>
          <Grid xs={12}><Textarea {...notes.bindings} width="$full" label="Notes"></Textarea></Grid>
        </Grid.Container>
        <Spacer y={0} />
        <Container gap={0.5}>
          {!props.new && <Text size={12} color="grey">Created {timestampCreated.toLocaleDateString()} {timestampCreated.toLocaleTimeString()}</Text>}
          {!props.new && <Text size={12} color="grey">Modified {timestampModified.toLocaleDateString()} {timestampModified.toLocaleTimeString()}</Text>}
        </Container>
        <Text color="error">{errorText}</Text>
      </Modal.Body>
      <Modal.Footer>
        <Row gap={1}>
          <Row>
            {!props.new && <Button onPress={deleteHandler} auto bordered flat css={{ color: "$gray500", borderColor: "$gray500" }}><Delete /></Button>}
          </Row>
          <Row justify="flex-end">
            <CancelButton onPress={cancelHandler} />
            <Spacer x={0.5} />
            {!props.new && <Button onPress={saveHandler}>Save</Button>}
            {props.new && <Button onPress={createHandler}>Create</Button>}
          </Row>
        </Row>
      </Modal.Footer>
    </Modal>
  );
}
